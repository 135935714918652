import React from "react";
import { useHistory } from "react-router-dom";
const companyLogo = require("../../assets/img/acu.svg");
const translationIcon = require("../../assets/img/user.svg");
const homeIcon = require("../../assets/img/home.svg");

const Header = () => {
  const [searchModalOpen, setSearchModalOpen] = React.useState(false);
  const history = useHistory();
  return (
    <div>
      <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            <div className="flex"></div>

            <div className="flex items-center">
              <button
                className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full ml-3 ${
                  searchModalOpen && "bg-slate-200"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  // setSearchModalOpen(true);
                  history.push(`/`);
                }}
                aria-controls="search-modal"
              >
                <span className="sr-only">Search</span>
                <img
                  className="w-6 h-6 rounded-none shadow-lg"
                  src={homeIcon.default}
                  alt="translation"
                />
              </button>
              <button
                className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full ml-3 ${
                  searchModalOpen && "bg-slate-200"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  // setSearchModalOpen(true);
                  history.push(`/admin`);
                }}
                aria-controls="search-modal"
              >
                <span className="sr-only">Search</span>
                <img
                  className="w-6 h-6 rounded-none shadow-lg"
                  src={translationIcon.default}
                  alt="translation"
                />
              </button>

              <hr className="w-px h-6 bg-slate-200 mx-3" />
              <p className="text-slate-500 text-sm">English</p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
