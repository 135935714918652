import React, { useEffect, useState } from "react";
import Dropdown from "../components/ui/dropdown";
import Loading from "../components/ui/loading";
import { Healers } from "../model/healers";
import DirectoryService from "../service/directoryService";
import HealersCard from "./ui/healersCard";
// const reactLogo = require('../assets/img/acu_logo.png');
// const xogo = require('../assets/img/react_logo.svg');

const Directory = () => {
  const [statesLoading, setStatesLoading] = useState<boolean>(false);
  const [districtLoading, setDistrictLoading] = useState<boolean>(false);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);

  const [states, setStates] = useState<any>([]);
  const [selectedState, setSelectedState] = useState<string>("");

  const [disableDistrict, setDisableDistrict] = useState<boolean>(true);
  const [district, setDistrict] = useState<any>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<string>("");

  const [disableCities, setDisableCities] = useState<boolean>(true);
  const [cities, setCities] = useState<any>([]);
  const [selectedCity, setSelectedCity] = useState<string>("");

  const [cityLoading, setCityLoading] = useState<boolean>(false);

  const [healers, setHealers] = useState<Healers>();

  useEffect(() => {
    (async () => {
      // await DirectoryService.getHealers(
      //   "TGode",
      //   "Namakkal",
      //   "Tamil Nadu",
      //   "omm",
      // );
      setStatesLoading(true);
      setCitiesLoading(true);
      setDistrictLoading(true);
      const states = await DirectoryService.getStates();
      setStates(states);
      setStatesLoading(false);
      setCitiesLoading(false);
      setDistrictLoading(false);
    })();
  }, []);

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-col space-y-2">
        <div>States:</div>
        {statesLoading ? (
          <Loading />
        ) : (
          <Dropdown
            initialMessage={"Select the states"}
            options={states}
            onSelectOption={async (key, value) => {
              setSelectedState(value);
              setDisableDistrict(true);
              setDisableCities(true);
              setDistrictLoading(true);

              const districts = await DirectoryService.getDistricts(value, key);
              setDistrictLoading(false);
              setDistrict(districts);
              setDisableDistrict(false);
            }}
          />
        )}
      </div>

      <div className="flex flex-col space-y-2">
        <div>District:</div>
        {districtLoading ? (
          <Loading />
        ) : (
          <Dropdown
            initialMessage={"Select the district"}
            options={district}
            disabled={disableDistrict}
            onSelectOption={async (key, value) => {
              setCitiesLoading(true);
              setDisableCities(true);
              setSelectedDistrict(value);
              const cities = await DirectoryService.getCities(value, key);
              setCities(cities);
              setDisableCities(false);
              setCitiesLoading(false);
            }}
          />
        )}
      </div>

      <div className="flex flex-col space-y-2">
        <div>Cities:</div>
        {citiesLoading ? (
          <Loading />
        ) : (
          <Dropdown
            initialMessage={"Select the city"}
            options={cities}
            disabled={disableCities}
            onSelectOption={async (key, value) => {
              setSelectedCity(value);

              const healersList = await DirectoryService.getHealers(
                value,
                selectedDistrict,
                selectedState,
                "",
              );

              setHealers(healersList);
            }}
          />
        )}
      </div>
      <div>Healers:</div>

      <div>
        {healers ? (
          <div>
            {Object.entries(healers).map(([key, value]) => {
              return <HealersCard key={key} healer={value} />;
            })}
          </div>
        ) : (
          <div>Not found</div>
        )}
      </div>
    </div>
  );
};

export default Directory;
