import React from "react";
const reactLogo = require("../../assets/img/filter.svg");

const HealerDefaultView = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-8 mb-8">
      <img
        className="w-48 h-48 rounded-none "
        src={reactLogo.default}
        alt="Acu home"
      />
      <p>Choose your area to find Acuhealer Details</p>
    </div>
  );
};

export default HealerDefaultView;
